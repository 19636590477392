<template>
  <div class="download-wrap" :style="{ 'background-image': `url(${bgUrl})` }">
    <div class="download-body" :style="{ 'min-height': '100vh' }">
      <div class="download-body-top">
        <img :src="appData.download_logo && appData.download_logo.content" alt="" />
        <div class="title_box">
          <div class="title">{{ appData.download_title && appData.download_title.content }}</div>
          <div class="sub_title">{{ appData.download_sub_title && appData.download_sub_title.content }}</div>
        </div>
      </div>
      <div class="img_box">
        <img src="../../../assets/imgs/image_merchantside@3x.png" style="width: 100%" alt="" />
        <!-- 下载页图片1 -->
        <img class="img_one" :src="appData.download_img_one && appData.download_img_one.content" alt="" />
        <!-- 下载页图片2 -->
        <img class="img_two" :src="appData.download_img_two && appData.download_img_two.content" alt="" />
      </div>
    </div>
    <div class="download-btn">
      <van-button class="btn" @click="download" color="#ee1c22" :loading="loading">立即下载</van-button>
    </div>
    <action-sheet v-model="show" :actions="actions" @select="onSelect" :round="false" :close-on-click-action="true" />
  </div>
</template>

<script>
import downloadMixin from '@/mixin/downloadMixin'
import { isWeixin, getPhoneBrand } from '@/utils/utils'
export default {
  name: 'Download',
  mixins: [downloadMixin],
  computed: {
    bgUrl() {
      return (this.appData.download_bg_image && this.appData.download_bg_image.content) || ''
    },
  },
  methods: {
    onSelect(item) {
      if (item.type === 0) {
        this.linkDown(this.info.path)
      } else {
        this.linkDown(this.brandList[this.brand] + this.apkId)
      }
      this.downloadCount()
    },
    download() {
      this.loading = true
      if (isWeixin()) {
        this.linkDown('https://sj.qq.com/appdetail/' + this.apkId)
        setTimeout(() => {
          this.loading = false
        }, 2000)
        return
      }
      if (this.type === 0) {
        //安卓
        this.brand = getPhoneBrand()
        if (this.brand != 'other') {
          this.show = true
        } else {
          this.linkDown(this.info.path)
        }
      } else {
        let _href = (this.appData.downloadLink && this.appData.downloadLink.content) || null
        this.linkDown(_href)
      }
      setTimeout(() => {
        this.loading = false
      }, 2000)
    },
    async downloadCount() {
      // 用户点击了下载按钮 应该调统计下载次数接口了
      const id = this.info.id
      const { data } = await this.$axios({
        url: `${this.envId == 1 ? '/canary/' : '/'}admin/app/downloadStatistics?id=${id}`,
      })
      if (data.code === 200) {
        console.log('下载统计成功')
      }
    },
    async getInfo() {
      const { success, result } = await this.$axios({
        url: `/admin/app/android/version/0/${this.envId}`,
      })
      if (success) {
        this.info = result
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.download-wrap {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-color: #fff;
  background-repeat: no-repeat;
}
.download-body {
  padding-top: vv(20);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  &-top {
    display: flex;
    margin-bottom: vv(25);
    img {
      width: vv(64);
      height: vv(64);
      flex-shrink: 0;
      margin-right: vv(12);
    }
    .title_box {
      text-align: left;
      color: $red;
      .title {
        @include fontSemibold;
        font-size: vv(28);
        font-weight: 600;
      }
      .sub_title {
        font-size: vv(16);
        @include fontMedium;
        font-weight: 500;
      }
    }
  }

  .img_box {
    position: relative;
    z-index: 1;

    .img_one {
      position: absolute;
      bottom: 0;
      left: vv(-2);
      width: vv(112);
    }
    .img_two {
      position: absolute;
      bottom: vv(-36);
      right: vv(-24);
      width: vv(231);
      z-index: -1;
    }
  }
}
.download-btn {
  z-index: 100;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: vv(44);
  width: 100%;
  text-align: center;
  .btn {
    border-radius: vv(24);
    width: 60%;
    font-size: vv(18);
    font-weight: 500;
    color: #fff;
    padding: vv(11) 0;
  }
}
</style>
