import ActionSheet from 'vant/lib/action-sheet'
import 'vant/lib/action-sheet/style'
import Toast from 'vant/lib/toast'
import 'vant/lib/toast/style'
import Vue from 'vue'
import Button from 'vant/lib/button'
import 'vant/lib/button/style'
Vue.use(Button)
import { mapGetters } from 'vuex'
const downloadMixin = {
  data() {
    return {
      clientHeight: '100vh',
      show: false,
      type: '',
      info: {},
      actions: [
        { name: '本地下载', type: 0 },
        {
          name: '应用市场下载',
          type: 1,
        },
      ],
      envId: 2, // env环境，默认生态产適用環境：0-測試；1-灰度；2-生產
      // 手机品牌跳转商店列表
      brandList: {
        vivo: 'vivoMarket://details?id=',
        huawei: 'appmarket://details?id=',
        mi: 'mimarket://details?id=',
        oppo: 'oppomarket://details?packagename=',
        tencent: 'tmast://appdetails?pname=',
      },
      apkId: 'com.ihk.merchant', // i餐饮包名
      apkHdId: 'com.ihk.merchant.machine',
      brand: '', // 安卓品牌
      loading: false
    }
  },
  components: {
    ActionSheet,
  },
  computed: {
    ...mapGetters({
      appData: 'app/appData',
    }),
  },
  async created() {
    let clientHeight = this.getViewPortHeight()
    this.clientHeight = clientHeight > 0 ? `${clientHeight}px` : '100vh'
    // 获取环境
    this.envId = await this.getEnvId()
    await this.getMobile()
  },

  methods: {
    importJS() {
      let _hmt = _hmt || []
      let hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?49355e98ea54827eaf0d63df264b3a9a'
      let s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    },
    linkDown(href) {
      if (!href) return Toast('暂无资源')
      let a = document.createElement('a')
      a.href = href
      a.click()
    },

    /**
     * 获取当前环境
     * @returns envId
     */
    getEnvId() {
      return new Promise((resolve) => {
        let env = process.env.NODE_ENV || 'tests'
        let envId = env == 'production' ? 2 : 0
        resolve(envId)
      })
    },
    // 获取浏览器窗口的可视区域的高度
    getViewPortHeight() {
      return document.documentElement.clientHeight || document.body.clientHeight || 0
    },
    // 获取用户代理系统类型
    async getMobile() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('iphone') != -1) {
        this.type = 1
        return 'ios'
      } else if (ua.indexOf('android') != -1) {
        this.type = 0
        await this.getInfo()
        return 'android'
      } else if (ua.indexOf('windows') != -1) {
        return 'windows'
      } else {
        return 'none'
      }
    },
  }
}
export default downloadMixin
